import { Button, Col, Row } from "react-bootstrap";
import { FilterDropdown } from "../FilterDropdown";
import { IChartFiltersProps } from "./types";
import { FC } from "react";
import { addFilter } from "../../utils/addFilter";
import { transformCamelCase } from "../../utils/transformString";
import { IChartFilters } from "../../models/IChartFilters";
import { FilterItemList } from "../FilterItemList";

export const ChartFilters: FC<IChartFiltersProps> = ({
  currentFilters,
  setCurrentFilters,
  availableFilters,
  setLastFilter,
}) => {
  const resetFilters = () => {
    setCurrentFilters({
      "Hotel Name": [],
      "Hotel Type": [],
      State: [],
      Category: [],
      City: [],
      BrandTier: [],
    });
  };
  return (
    <fieldset className="mt-4 border border-secondary p-4 rounded">
      <legend className="w-auto float-none px-2">Filters</legend>
      <div className="d-flex align-items-center flex-column gap-4">
        <div className="d-flex gap-2 flex-wrap flex-row d-xl-none">
          {Object.keys(availableFilters).map((filter) => {
            return (
              <FilterDropdown
                key={filter}
                title={transformCamelCase(filter)}
                setValue={(value: string) => {
                  setLastFilter(filter);
                  setCurrentFilters(addFilter(currentFilters, filter, value));
                }}
                selectedValues={currentFilters[filter as keyof IChartFilters]}
                availableValues={
                  availableFilters[filter as keyof IChartFilters]
                }
              />
            );
          })}
          <FilterDropdown title={"Member Level"} disabled={true} />
          <FilterDropdown title={"Owner Type"} disabled={true} />
        </div>
        <Row className="w-100 justify-content-center d-none d-xl-flex">
          {Object.keys(availableFilters).map((filter) => {
            return (
              <Col key={filter}>
                <FilterDropdown
                  title={transformCamelCase(filter)}
                  setValue={(value: string) => {
                    setLastFilter(filter);
                    setCurrentFilters(addFilter(currentFilters, filter, value));
                  }}
                  selectedValues={currentFilters[filter as keyof IChartFilters]}
                  availableValues={
                    availableFilters[filter as keyof IChartFilters]
                  }
                />
                {!!currentFilters[filter as keyof IChartFilters].length && (
                  <div className="rounded border border-secondary mt-3 p-2 d-flex flex-column align-items-start">
                    {currentFilters[filter as keyof IChartFilters].map(
                      (filterValue: string) => {
                        return (
                          <FilterItemList
                            key={filterValue}
                            value={filterValue}
                            setValue={(value: string) => {
                              setCurrentFilters(
                                addFilter(currentFilters, filter, value)
                              );
                            }}
                          />
                        );
                      }
                    )}
                  </div>
                )}
              </Col>
            );
          })}

          <Col>
            <FilterDropdown title={"Member Level"} disabled={true} />
          </Col>
          <Col>
            <FilterDropdown title={"Owner Type"} disabled={true} />
          </Col>
        </Row>
        <Button onClick={resetFilters} variant="secondary">
          Reset filters
        </Button>
      </div>
    </fieldset>
  );
};
