import { Outlet } from "react-router-dom";
import { Header } from "../../components";

export const Layout = () => {
  return (
    <div className="p-4">
      <Header />
      <Outlet />
    </div>
  );
};
