export const addFilter = <T extends Record<string, any>>(
  currentFilters: T,
  key: string,
  value: string
) => {
  if (currentFilters[key]?.includes(value)) {
    return {
      ...currentFilters,
      [key]: currentFilters[key].filter((e: string) => e !== value),
    };
  }
  return {
    ...currentFilters,
    [key]: [...currentFilters[key], value],
  };
};
