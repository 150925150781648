import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { chartColors, month } from "../../consts";
import { IYearSentimentProps } from "./types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const YearSentiment: React.FC<IYearSentimentProps> = ({ dataset }) => {
  const getFormattedDataset = () => {
    const res = [];
    for (let year in dataset) {
      res.push({
        label: year,
        data: dataset[year],
        backgroundColor: chartColors[res.length],
        borderColor: chartColors[res.length],
      });
    }
    return res;
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Monthly sentiment scores by year",
      },
    },
  };

  const data = {
    labels: month,
    datasets: getFormattedDataset(),
  };

  return <Line options={options} data={data} />;
};
