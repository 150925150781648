import { useLottie } from "lottie-react";
import notFoundAnimation from "../../assets/animations/not-found.json";
import "./styles.scss";
import { Button } from "react-bootstrap";
import { AiOutlineLineChart } from "react-icons/ai";
import { SiOpenstreetmap } from "react-icons/si";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const options = {
    animationData: notFoundAnimation,
    loop: false,
  };

  const { View } = useLottie(options);
  const navigate = useNavigate();

  return (
    <div className="not-found-wrapper">
      <div className="not-found-wrapper__content">
        <div className="not-found-wrapper__animation">{View}</div>
        <div className="w-100 d-flex justify-content-around">
          <Button
            onClick={() => navigate("/charts")}
            variant="secondary"
            className="d-flex justify-content-between align-items-center flex-row gap-2"
          >
            <span>Chart view</span>
            <AiOutlineLineChart size={40} />
          </Button>
          <Button
            onClick={() => navigate("/maps")}
            variant="secondary"
            className="d-flex justify-content-between align-items-center flex-row gap-2"
          >
            <span>Map view</span>
            <SiOpenstreetmap size={40} />
          </Button>
        </div>
      </div>
    </div>
  );
};
