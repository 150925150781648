export const applyFilters = <T extends Record<string, any>>(
  data: T[],
  filters: Record<string, any>
) => {
  const filterKeysLength = Object.keys(filters);
  return data.filter((item) => {
    const inFilters: boolean[] = [];
    for (let filterKey in filters) {
      if (!filters[filterKey].length) {
        inFilters.push(true);
      } else {
        inFilters.push(filters[filterKey].includes(item[filterKey as keyof T]));
      }
    }
    const filteredArray = inFilters.filter((e) => e);
    return filterKeysLength.length === filteredArray.length;
  });
};
