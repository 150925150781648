import { ChangeEvent, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import csv from "csvtojson";
import { IFileInputChartProps } from "./types";
import xlsxParser from "xlsx-parse-json";
import "./style.scss";
import { IXLSX } from "../../models/IXLSX";
import { isFileValid } from "../../utils/isFileValid";

export const FileInputChart: React.FC<IFileInputChartProps> = ({
  setData,
  data,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const onLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const example = {
      BrandTier: "",
      Category: "",
      City: "",
      "Hotel Name": "",
      "Hotel Type": "",
      State: "",
      address: "",
      cleanliness: "",
      cnf: "",
      country: "",
      date: "",
      date_cleaned: "",
      emotion_score: "",
      emotion_tag: "",
      food: "",
      internet: "",
      latitude: "",
      location: "",
      longitude: "",
      month: "",
      pool_spa: "",
      postalCode: "",
      sentiment_rating: "",
      sentiment_score: "",
      sentiment_tag: "",
      staff: "",
      year: "",
      year_month: "",
    };
    const files = event.target.files;
    if (files) {
      const file = files[0];
      if (file.type === "text/csv") {
        const fileContent = await file.text();
        const csvData = await csv().fromString(fileContent);
        if (isFileValid(csvData, example)) {
          setData(csvData.filter((hotel) => hotel["Hotel Name"]));
          setIsError(false);
        } else {
          setIsError(true);
        }
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        xlsxParser.onFileSelection(file).then((data: IXLSX) => {
          if (isFileValid(data.tagged_sentiment_emotion, example)) {
            setData(
              data.tagged_sentiment_emotion.filter((hotel) => hotel.address)
            );
            setIsError(false);
          } else {
            setIsError(true);
          }
        });
      }
    }
  };

  return null;

  return (
    <>
      <fieldset className="mt-3 border border-secondary p-4 rounded file-input__wrapper">
        <legend className="w-auto float-none px-2">
          Upload your file here
        </legend>
        <Form.Group>
          <Form.Control
            type="file"
            className="file-input__wrapper__input"
            onChange={onLoadFile}
          />
        </Form.Group>
      </fieldset>
      {isError && (
        <Alert
          variant="danger"
          onClose={() => setIsError(false)}
          dismissible
          className="mt-2"
        >
          <Alert.Heading>Invalid file!</Alert.Heading>
          <p>
            Please check fields in your file and try again.{" "}
            {data && "(previous file's content is shown)"}.
          </p>
        </Alert>
      )}
    </>
  );
};
