import { useLottie } from "lottie-react";
import noDataAnimation from "../../assets/animations/no-data.json";
import "./styles.scss";

export const EmptyList = () => {
  const options = {
    animationData: noDataAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <div className="no-data-wrapper">
      <div className="no-data-wrapper__content">
        <div className="no-data-wrapper__animation">{View}</div>
        <h5>No data was found by your filters</h5>
      </div>
    </div>
  );
};
