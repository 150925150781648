import { FC, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineLineChart } from "react-icons/ai";
import { SiOpenstreetmap } from "react-icons/si";

export const Header: FC = () => {
  const [isMaps, setIsMaps] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentLocationSplit = location.pathname.split("/");
    const currentRoute = currentLocationSplit[currentLocationSplit.length - 1];
    if (currentRoute === "maps") {
      setIsMaps(true);
    } else {
      setIsMaps(false);
    }
  }, [location.pathname]);

  return (
    <header>
      <Row className="align-items-center w-100">
        <Col className="col-5 col-xl-2 d-flex flex-column align-items-center">
          <img
            src={require("../../assets/logo.png")}
            className="logo rounded"
            alt="logo"
          />
        </Col>
        <Col>
          <h2 className="d-none d-xl-block text-center m-0">
            Voice of Customer Dashboard{isMaps ? ": Map View" : null}
          </h2>
        </Col>
        <Col className="col-5 col-xl-2 d-flex flex-column align-items-center">
          <div className="text-nowrap">
            {isMaps ? (
              <Button
                onClick={() => navigate("/charts")}
                variant="secondary"
                className="d-flex justify-content-between align-items-center flex-row gap-2"
              >
                <span>Chart view</span>
                <AiOutlineLineChart size={40} />
              </Button>
            ) : (
              <Button
                onClick={() => navigate("/maps")}
                variant="secondary"
                className="d-flex justify-content-between align-items-center flex-row gap-2"
              >
                <span>Map view</span>
                <SiOpenstreetmap size={40} />
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </header>
  );
};
