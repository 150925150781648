import { Table } from "react-bootstrap";
import { ITierTableProps } from "./types";
import { monthFull } from "../../consts";
import {
  ITierSentiment,
  ITierSentimentItem,
} from "../../models/ITierSentiment";

export const TierTable: React.FC<ITierTableProps> = ({ dataset, month }) => {
  const getFormattedData = () => {
    let res = [] as string[][];
    for (let key in dataset) {
      const tierValues: number[] = [];
      for (let subKey in dataset[key as keyof ITierSentiment]) {
        tierValues.push(
          dataset[key as keyof ITierSentiment][
            subKey as keyof ITierSentimentItem
          ]
        );
      }
      const avg = (tierValues.reduce((a, b) => a + b, 0) / tierValues.length)
        .toFixed(2)
        .toString();

      res.push([
        key,
        ...tierValues.map((value) => value.toFixed(2).toString()),
        avg,
      ]);
    }

    return res;
  };

  const getOverall = () => {
    const allValues: number[] = [];
    const sumForTier: number[] = [0, 0, 0, 0];
    for (let key in dataset) {
      const valuesForHotelType: number[] = [];
      for (let subKey in dataset[key as keyof ITierSentiment]) {
        const currentValue =
          dataset[key as keyof ITierSentiment][
            subKey as keyof ITierSentimentItem
          ];
        valuesForHotelType.push(currentValue);
        allValues.push(currentValue);
      }
    }

    let counter = 0;
    for (let i = 0; i < allValues.length; i++) {
      sumForTier[counter] += allValues[i];
      counter++;
      if (counter === 4) {
        counter = 0;
      }
    }

    const res = [
      ...sumForTier.map((e) => e / 5),
      allValues.reduce((a, b) => a + b, 0) / allValues.length,
    ];
    return res.map((el) => el.toFixed(2));
  };

  return (
    <Table bordered hover>
      <thead className="bg-light">
        <tr>
          <th>{monthFull[month - 1]} Scores Sentiment</th>
          <th colSpan={5}>Brand Tier</th>
        </tr>
        <tr>
          <th>Hotel Type</th>
          <th>Platinum</th>
          <th>Gold</th>
          <th>Silver</th>
          <th>Bronze</th>
          <th>Overall</th>
        </tr>
      </thead>
      <tbody>
        {getFormattedData().map((renderArr) => {
          return (
            <tr key={renderArr[0]}>
              {renderArr.map((renderArrItem, index) => {
                return <td key={index}>{renderArrItem}</td>;
              })}
            </tr>
          );
        })}
        <tr className="bg-light">
          <td>Overall</td>
          {getOverall().map((item, index) => {
            return <td key={index}>{item}</td>;
          })}
        </tr>
      </tbody>
    </Table>
  );
};
