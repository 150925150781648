import React, { useEffect, useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { IStateViewMapProps } from "./types";
import { IStateView } from "../../models/IStateView";
import state from "us-state-converter";
import { Card, Col, Row } from "react-bootstrap";
import { getColorByPercent } from "../../utils/getColorByPercent";

export const StateViewMap: React.FC<IStateViewMapProps> = ({ dataset }) => {
  const [selectedState, setSelectedState] = useState<IStateView | null>(null);
  const [datasetMaxValue, setDatasetMaxValue] = useState<number>(0);

  useEffect(() => {
    let maxValue = 0;
    for (let el of dataset) {
      if (+el["Avg Sentiment Score this month"] > maxValue) {
        maxValue = +el["Avg Sentiment Score this month"];
      }
    }
    setDatasetMaxValue(maxValue);
  }, [dataset]);

  return (
    <div className="mt-4">
      <Row>
        <Col className="col-8">
          <ComposableMap projection="geoAlbersUsa">
            <Geographies geography="https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json">
              {({ geographies }) =>
                geographies.map((geo) => {
                  let defaultFill = "#000";
                  let isChosen = false;
                  const currentAbbr = state.abbr(geo.properties.name);
                  const objWithCurrentState = dataset.filter(
                    (el) => el.State === currentAbbr
                  )[0];
                  if (objWithCurrentState) {
                    defaultFill = getColorByPercent(
                      (100 *
                        +objWithCurrentState[
                          "Avg Sentiment Score this month"
                        ]) /
                        datasetMaxValue,
                      1
                    );
                  }

                  if (selectedState?.State === currentAbbr) {
                    isChosen = true;
                  }
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onClick={() => {
                        if (objWithCurrentState) {
                          setSelectedState(objWithCurrentState);
                        }
                      }}
                      style={{
                        default: {
                          fill: isChosen ? "#F53" : defaultFill,
                          outline: "none",
                        },
                        hover: {
                          fill: "#F53",
                          cursor: "pointer",
                          outline: "none",
                        },

                        pressed: {
                          fill: "#E42",
                          cursor: "pointer",
                          outline: "none",
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
        </Col>
        <Col>
          {selectedState && (
            <Card>
              <Card.Body>
                <Card.Title>{state.fullName(selectedState.State)}</Card.Title>
                <Card.Text>
                  Avg Sentiment Score this month:{" "}
                  {selectedState["Avg Sentiment Score this month"]} (
                  {selectedState["Percentage Change over last month"]})
                </Card.Text>
                <Card.Text>
                  Avg Sentiment Score this month:{" "}
                  {selectedState["Avg Emotion Score this month"]}
                </Card.Text>
                <Card.Text>
                  Number of Hotels: {selectedState["Number of Hotels"]}
                </Card.Text>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};
