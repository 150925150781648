import { getFiltersForData } from "./getFiltersForData";

export const getLimitedFilters = <T extends Record<string, any>>(
  data: T[],
  filteredData: T[],
  currentFilters: any,
  lastFilter: string
) => {
  if (!lastFilter) return getFiltersForData(data, currentFilters);
  const currentFiltersWithoutSet = {
    ...getFiltersForData(filteredData, currentFilters),
    [lastFilter]: getFiltersForData(data, currentFilters)[lastFilter],
  };

  for (let filterKey in currentFilters) {
    currentFiltersWithoutSet[filterKey] = Array.from(
      new Set([
        ...currentFiltersWithoutSet[filterKey],
        ...currentFilters[filterKey],
      ])
    ) as T[Extract<keyof T, string>];
  }

  return currentFiltersWithoutSet;
};
