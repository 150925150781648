import { IFilterItemListProps } from "./types";
import { MdClose } from "react-icons/md";
import "./styles.scss";

export const FilterItemList: React.FC<IFilterItemListProps> = ({
  value,
  setValue,
}) => {
  return (
    <div
      title={value}
      className="text-primary d-flex align-items-center filter-item"
      onClick={() => setValue(value)}
    >
      <MdClose size={18} color="#0d6efd" />
      <div className="filter-item__text">{value}</div>
    </div>
  );
};
