export const getColorByPercent = (percent: number, colorsCount: 1 | 2) => {
  const roundedPercent = Math.round(percent / 10) * 10;
  if (colorsCount === 1) {
    switch (roundedPercent) {
      case 10:
        return "#dbeafe";
      case 20:
        return "#bfdbfe";
      case 30:
        return "#93c5fd";
      case 40:
        return "#60a5fa";
      case 50:
        return "#3b82f6";
      case 60:
        return "#2563eb";
      case 70:
        return "#1d4ed8";
      case 80:
        return "#1e40af";
      case 90:
        return "#1e3a8a";
      case 100:
        return "#172554";
      default:
        return "#eff6ff";
    }
  }
  if (colorsCount === 2) {
    switch (roundedPercent) {
      case 10:
        return "#EF4444"; // Red
      case 20:
        return "#F87171"; // Rose
      case 30:
        return "#FCA5A5"; // Pink
      case 40:
        return "#FBBF24"; // Amber
      case 50:
        return "#FDE047"; // Yellow
      case 60:
        return "#84CC16"; // Lime
      case 70:
        return "#22C55E"; // Green
      case 80:
        return "#10B981"; // Emerald
      case 90:
        return "#14B8A6"; // Teal
      case 100:
        return "#06B6D4"; // Cyan
      default:
        return "#EF4444"; // Red
    }
  }

  return "#000";
};
