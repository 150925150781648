import { IHotel } from "../models/IHotel";
import { IMonthSentiment } from "../models/IMonthSentiment";

export const getMonthSentiment = (data: IHotel[]) => {
  let lastYear = 0;

  const getScheme = () => {
    return JSON.parse(
      JSON.stringify({
        cleanliness: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        staff: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        food: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        cnf: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        internet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        location: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        pool_spa: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      })
    );
  };
  const itemsSum: IMonthSentiment = getScheme();
  const itemsLength: IMonthSentiment = getScheme();
  for (let hotel of data) {
    if (+hotel.year > lastYear) {
      lastYear = +hotel.year;
    }
  }

  const lastYearInstances = data.filter((hotel) => +hotel.year === lastYear);
  for (let hotel of lastYearInstances) {
    const hotelMonth = +hotel.month - 1;
    for (let sentimentKey in itemsSum) {
      const currentArr = itemsSum[sentimentKey as keyof IMonthSentiment];
      currentArr[hotelMonth] += +hotel[sentimentKey as keyof IHotel];
      itemsSum[sentimentKey as keyof IMonthSentiment] = currentArr;

      const currentLengthArr =
        itemsLength[sentimentKey as keyof IMonthSentiment];
      currentLengthArr[hotelMonth] += 1;
      itemsLength[sentimentKey as keyof IMonthSentiment] = currentLengthArr;
    }
  }

  const result = {} as IMonthSentiment;

  for (let hotelKey in itemsSum) {
    const hotelAvg: number[] = [];
    const hotelArr = itemsSum[hotelKey as keyof IMonthSentiment];
    const lengthArr = itemsLength[hotelKey as keyof IMonthSentiment];

    for (let i = 0; i < hotelArr.length; i++) {
      hotelAvg.push(hotelArr[i] / lengthArr[i]);
    }
    result[hotelKey as keyof IMonthSentiment] = hotelAvg;
  }
  return result;
};
