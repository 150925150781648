import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import {
  CustomMenuProps,
  CustomToggleProps,
  IFilterDropdownProps,
} from "./types";
import "./styles.scss";
import { cutString } from "../../utils/cutString";
import { Button } from "react-bootstrap";

const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
    <Button
      {...props}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      variant="secondary"
    >
      {props.children}
    </Button>
  )
);

const CustomMenu = React.forwardRef(
  (props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={props.style}
        className={props.className}
        aria-labelledby={props.labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled dropdown-content">
          {React.Children.toArray(props.children).filter(
            (child: any) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

export const FilterDropdown: React.FC<IFilterDropdownProps> = ({
  title,
  setValue,
  selectedValues,
  availableValues,
  disabled,
}) => {
  const isInFilters = (value: string) => {
    return selectedValues?.includes(value);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        disabled={!!disabled}
        className="w-100 justify-content-between d-flex align-items-center"
      >
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {availableValues?.length && setValue ? (
          availableValues.map((filterItem) => {
            return (
              <Dropdown.Item
                title={filterItem}
                onClick={(e) => {
                  e.stopPropagation();
                  setValue(filterItem);
                }}
                key={filterItem}
                className={
                  isInFilters(filterItem)
                    ? "text-primary text-decoration-underline"
                    : "text-secondary"
                }
              >
                {cutString(filterItem, 30)}
              </Dropdown.Item>
            );
          })
        ) : (
          <Dropdown.Item>No items for this filter</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
