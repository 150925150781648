import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Charts, Layout, Maps, NotFound } from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/charts" />} />
          <Route path="/charts" element={<Charts />} />
          <Route path="/maps" element={<Maps />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
