export const getFiltersForData = <T extends Record<string, any>>(
  data: T[],
  filters: T
) => {
  const res = {} as T;
  for (let key in filters) {
    res[key] = Array.from(
      new Set(data.map((hotel) => hotel[key]))
    ) as T[Extract<keyof T, string>];
  }

  return res;
};
