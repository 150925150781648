export const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const chartColors = [
  "#0d6efd",
  "#dc3545",
  "#ffc107",
  "#6f42c1",
  "#fd7e14",
  "#198754",
  "#d63384",
  "#20c997",
];

export const monthFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
