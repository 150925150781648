import { useEffect, useState } from "react";
import { IStateView } from "../../models/IStateView";
import {
  FileInputStateView,
  FileInputHotelView,
  StateViewMap,
  MapsFilters,
  HotelViewMap,
  AddFile,
} from "../../components";
import { IHotelView } from "../../models/IHotelView";
import { IMapsFilter } from "../../models/IMapsFilter";
import { getUniqueValues } from "../../utils/getUniqueValues";
import { applyFilters } from "../../utils/applyFilters";
import { getLimitedFilters } from "../../utils/getLimitedFilters";
import dataJSON from "../../data/GeoData_origin.json";

export const Maps = () => {
  const [stateViewData, setStateViewData] = useState<IStateView[] | null>(null);
  const [hotelViewData, setHotelViewData] = useState<IHotelView[] | null>(null);
  const [availableFilters, setAvailableFilters] = useState<IMapsFilter>({
    Category: [],
    "Brand Tier": [],
  });
  const [currentFilters, setCurrentFilters] = useState<IMapsFilter>({
    Category: [],
    "Brand Tier": [],
  });
  const [filteredData, setFilteredData] = useState<IHotelView[]>([]);
  const [lastFilter, setLastFilter] = useState<string>("");

  useEffect(() => {
    const stateData = dataJSON.StateView as any[];
    const hotelData = dataJSON.HotelView as any[];

    if (stateData) {
      setStateViewData(stateData);
    }

    if (hotelData) {
      setHotelViewData(hotelData);
    }
  }, []);

  useEffect(() => {
    if (hotelViewData) {
      setAvailableFilters({
        Category: getUniqueValues<IHotelView>(hotelViewData, "Category"),
        "Brand Tier": getUniqueValues<IHotelView>(hotelViewData, "Brand Tier"),
      });
    }
  }, [hotelViewData]);

  useEffect(() => {
    if (hotelViewData) {
      setFilteredData(applyFilters(hotelViewData, currentFilters));
    }
  }, [currentFilters, hotelViewData]);

  useEffect(() => {
    if (hotelViewData) {
      setAvailableFilters(
        getLimitedFilters(
          hotelViewData,
          filteredData,
          currentFilters,
          lastFilter
        )
      );
    }
  }, [hotelViewData, filteredData, currentFilters, lastFilter]);

  return (
    <div>
      <FileInputStateView setData={setStateViewData} data={stateViewData} />
      {stateViewData ? <StateViewMap dataset={stateViewData} /> : <AddFile />}
      <FileInputHotelView setData={setHotelViewData} data={hotelViewData} />
      {hotelViewData ? (
        <>
          <MapsFilters
            setLastFilter={setLastFilter}
            availableFilters={availableFilters}
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
          />
          <HotelViewMap
            dataset={hotelViewData}
            filteredDataset={filteredData}
          />
        </>
      ) : (
        <AddFile />
      )}
    </div>
  );
};
