import { useEffect, useState } from "react";
import { IHotel } from "../../models/IHotel";
import { IChartFilters } from "../../models/IChartFilters";
import { getUniqueValues } from "../../utils/getUniqueValues";
import { applyFilters } from "../../utils/applyFilters";
import { getLimitedFilters } from "../../utils/getLimitedFilters";
import {
  AddFile,
  EmotionTags,
  EmotionTagsForYear,
  EmptyList,
  FileInputChart,
  ChartFilters,
  MonthSentiment,
  TierTable,
  YearSentiment,
} from "../../components";
import { Col, Row } from "react-bootstrap";
import { getYearSentiments } from "../../utils/getYearSentiments";
import { getMonthSentiment } from "../../utils/getMonthSentiment";
import { getEmotionTagsForMonthByTypes } from "../../utils/getEmotionTagsForMontsByTypes";
import { getEmotionTagsForYear } from "../../utils/getEmotionTagsForYear";
import { getTierSentiment } from "../../utils/getTierSentiment";
import dataJSON from "../../data/tagged_sentiment_emotion.json";

export const Charts = () => {
  const [data, setData] = useState<IHotel[] | null>(null);
  const [availableFilters, setAvailableFilters] = useState<IChartFilters>({
    "Hotel Name": [],
    "Hotel Type": [],
    State: [],
    Category: [],
    City: [],
    BrandTier: [],
  });
  const [currentFilters, setCurrentFilters] = useState<IChartFilters>({
    "Hotel Name": [],
    "Hotel Type": [],
    State: [],
    Category: [],
    City: [],
    BrandTier: [],
  });
  const [filteredData, setFilteredData] = useState<IHotel[]>([]);
  const [lastFilter, setLastFilter] = useState<string>("");

  useEffect(() => {
    if (data) {
      setAvailableFilters({
        "Hotel Name": getUniqueValues<IHotel>(data, "Hotel Name"),
        "Hotel Type": getUniqueValues<IHotel>(data, "Hotel Type"),
        State: getUniqueValues<IHotel>(data, "State"),
        Category: getUniqueValues<IHotel>(data, "Category"),
        City: getUniqueValues<IHotel>(data, "City"),
        BrandTier: getUniqueValues<IHotel>(data, "BrandTier"),
      });
    }
  }, [data]);

  useEffect(() => {
    const filteredData = (
      (dataJSON as any).tagged_sentiment_emotion as any[]
    ).filter((hotel) => hotel?.address);
    if (filteredData) {
      setData(filteredData);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setFilteredData(applyFilters(data, currentFilters));
    }
  }, [currentFilters, data]);

  useEffect(() => {
    if (data) {
      setAvailableFilters(
        getLimitedFilters(data, filteredData, currentFilters, lastFilter)
      );
    }
  }, [data, filteredData, currentFilters, lastFilter]);

  const renderContent = () => {
    if (!data) {
      return <AddFile />;
    }

    if (!filteredData.length) {
      return <EmptyList />;
    }

    return (
      <div className="d-flex flex-column gap-4 m-auto">
        <Row>
          <Col className="d-flex justify-content-center p-5">
            <YearSentiment dataset={getYearSentiments(filteredData)} />
          </Col>
          <Col className="d-flex justify-content-center p-5">
            <MonthSentiment dataset={getMonthSentiment(filteredData)} />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center p-5">
            <EmotionTags
              dataset={getEmotionTagsForMonthByTypes(filteredData)}
              month={4}
            />
          </Col>
          <Col className="d-flex justify-content-center p-5">
            <EmotionTagsForYear dataset={getEmotionTagsForYear(filteredData)} />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center p-5">
            <TierTable dataset={getTierSentiment(filteredData)} month={4} />
          </Col>
          <Col />
        </Row>
      </div>
    );
  };
  return (
    <div>
      <FileInputChart setData={setData} data={data} />
      <ChartFilters
        setLastFilter={setLastFilter}
        availableFilters={availableFilters}
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
      />
      {renderContent()}
    </div>
  );
};
