import React, { useEffect, useState } from "react";
import { Map, Marker } from "pigeon-maps";
import { IHotelViewMapProps } from "./types";
import { getColorByPercent } from "../../utils/getColorByPercent";
import { IHotelView } from "../../models/IHotelView";
import { Card, Col, Row } from "react-bootstrap";
import state from "us-state-converter";
import "./styles.scss";

export const HotelViewMap: React.FC<IHotelViewMapProps> = ({
  dataset,
  filteredDataset,
}) => {
  const [maxScore, setMaxScore] = useState<number>(0);
  const [currentHotel, setCurrentHotel] = useState<IHotelView | null>(null);
  useEffect(() => {
    let maxScore = 0;
    for (let el of dataset) {
      const currentScore = +el["Avg Sentiment Score"];
      if (currentScore > maxScore) {
        maxScore = currentScore;
      }
    }
    setMaxScore(maxScore);
  }, [dataset]);
  return (
    <div className="mt-4">
      <Row>
        <Col className="col-8 map-wrapper">
          <Map defaultCenter={[47.116386, -101.299591]} defaultZoom={4}>
            {filteredDataset.map((el) => {
              let fillColor = getColorByPercent(
                (100 * +el["Avg Sentiment Score"]) / maxScore,
                2
              );
              return (
                <Marker
                  key={el["Hotel Name"]}
                  onClick={() => setCurrentHotel(el)}
                  width={30}
                  anchor={[+el.Latitude, +el.Longitude]}
                  color={fillColor}
                />
              );
            })}
          </Map>
        </Col>
        <Col>
          {currentHotel && (
            <Card>
              <Card.Body>
                <Card.Title>{currentHotel["Hotel Name"]}</Card.Title>
                <Card.Text>
                  State: {state.fullName(currentHotel["State Code"])}
                </Card.Text>
                <Card.Text>Category: {currentHotel.Category}</Card.Text>
                <Card.Text>Brand Tier: {currentHotel["Brand Tier"]}</Card.Text>
                <Card.Text>
                  Avg Sentiment Score: {currentHotel["Avg Sentiment Score"]}
                </Card.Text>
                <Card.Text>
                  Avg Emotion Score: {currentHotel["Avg Emotion Score"]}
                </Card.Text>
                <Card.Text>
                  Number of Responses: {currentHotel["Number of Responses"]}
                </Card.Text>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};
