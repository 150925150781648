import { IHotel } from "../models/IHotel";
import { IYearSentiments } from "../models/IYearSentiments";

export const getYearSentiments = (data: IHotel[]) => {
  const itemsSum: IYearSentiments = {};
  const itemsLength: IYearSentiments = {};
  for (let hotel of data) {
    const hotelYear = hotel.year;
    const hotelMonth = +hotel.month - 1;
    if (hotelYear in itemsSum) {
      const currentArr = itemsSum[hotelYear];
      currentArr[hotelMonth] += +hotel.sentiment_rating;
      itemsSum[hotelYear] = currentArr;

      const itemsLengthArr = itemsLength[hotelYear];
      itemsLengthArr[hotelMonth] += 1;
      itemsLength[hotelYear] = itemsLengthArr;
    } else {
      const newArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      newArr[hotelMonth] = +hotel.sentiment_rating;
      itemsSum[hotelYear] = newArr;

      const itemsLengthArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      itemsLengthArr[hotelMonth] = 1;
      itemsLength[hotelYear] = itemsLengthArr;
    }
  }

  let result: IYearSentiments = {};

  for (let hotelKey in itemsSum) {
    const hotelAvg: number[] = [];
    const hotelArr = itemsSum[hotelKey];
    const lengthArr = itemsLength[hotelKey];

    for (let i = 0; i < hotelArr.length; i++) {
      hotelAvg.push(hotelArr[i] / lengthArr[i]);
    }
    result[hotelKey] = hotelAvg;
  }

  return result;
};
