import { IHotel } from "../models/IHotel";
import { ITierSentiment, ITierSentimentItem } from "../models/ITierSentiment";

export const getTierSentiment = (data: IHotel[]) => {
  let lastYear = 0;
  let lastMonth = 4;

  for (let hotel of data) {
    if (+hotel.year > lastYear) {
      lastYear = +hotel.year;
    }
  }

  const currentMonthData = data.filter(
    (hotel) => +hotel.month === lastMonth && +hotel.year === lastYear
  );

  const getScheme = (): ITierSentiment => {
    const getSchemeItem = (): ITierSentimentItem => {
      return {
        Platinum: 0,
        Gold: 0,
        Silver: 0,
        Bronze: 0,
      };
    };
    return {
      "Bed & Breakfast & Inns,Lodging,Hotels": getSchemeItem(),
      "Hotels,Lodging,Resorts": getSchemeItem(),
      Hotel: getSchemeItem(),
      Resort: getSchemeItem(),
      "Hotels & Motels": getSchemeItem(),
    };
  };

  const itemsSum: ITierSentiment = getScheme();
  const itemsLength: ITierSentiment = getScheme();

  for (let hotel of currentMonthData) {
    const hotelType = hotel["Hotel Type"];
    const hotelTier = hotel.BrandTier;

    itemsSum[hotelType as keyof ITierSentiment][
      hotelTier as keyof ITierSentimentItem
    ] += +hotel.sentiment_rating;

    itemsLength[hotelType as keyof ITierSentiment][
      hotelTier as keyof ITierSentimentItem
    ] += 1;
  }

  const result: ITierSentiment = getScheme();

  for (let tagKey in itemsSum) {
    const tagAvg = {} as ITierSentimentItem;
    const tagSum = itemsSum[tagKey as keyof ITierSentiment];
    const tagLength = itemsLength[tagKey as keyof ITierSentiment];

    for (let tagItemKey in tagSum) {
      const avgCalcRes =
        tagSum[tagItemKey as keyof ITierSentimentItem] /
        tagLength[tagItemKey as keyof ITierSentimentItem];
      tagAvg[tagItemKey as keyof ITierSentimentItem] = isNaN(avgCalcRes)
        ? 0
        : avgCalcRes;
    }

    result[tagKey as keyof ITierSentiment] = tagAvg;
  }

  return result;
};
