import { useLottie } from "lottie-react";
import addFileAnimation from "../../assets/animations/add-file.json";
import "./styles.scss";

export const AddFile = () => {
  const options = {
    animationData: addFileAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <div className="add-file-wrapper">
      <div className="add-file-wrapper__content">
        <div className="add-file-wrapper__animation">{View}</div>
        <h5>Attach your CSV data file</h5>
      </div>
    </div>
  );
};
