export const transformCamelCase = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const transformKebabCase = (str: string) => {
  return str
    .split("_")
    .map((word) => {
      const splitWord = word.split("");
      splitWord[0] = splitWord[0].toUpperCase();
      return splitWord.join("");
    })
    .join(" ");
};
