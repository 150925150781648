import { IEmotionTagsForYear } from "../models/IEmotionTagsForMonth";
import { IHotel } from "../models/IHotel";

export const getEmotionTagsForYear = (data: IHotel[]) => {
  let lastYear = 0;

  for (let hotel of data) {
    if (+hotel.year > lastYear) {
      lastYear = +hotel.year;
    }
  }

  const filteredData = data.filter((hotel) => +hotel.year === lastYear);
  const getScheme = (): IEmotionTagsForYear => {
    return {
      anger: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      fear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      joy: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      love: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      sadness: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      surprise: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
  };

  const itemsSum = getScheme();
  const itemsLength = getScheme();

  for (let hotel of filteredData) {
    const hotelMonth = +hotel.month - 1;
    itemsSum[hotel.emotion_tag as keyof IEmotionTagsForYear][hotelMonth] +=
      +hotel.emotion_score;
    itemsLength[hotel.emotion_tag as keyof IEmotionTagsForYear][
      hotelMonth
    ] += 1;
  }

  const result = getScheme();

  for (let itemKey in itemsSum) {
    const hotelAvg: number[] = [];
    const hotelArr = itemsSum[itemKey as keyof IEmotionTagsForYear];
    const lengthArr = itemsLength[itemKey as keyof IEmotionTagsForYear];

    for (let i = 0; i < hotelArr.length; i++) {
      hotelAvg.push(hotelArr[i] / lengthArr[i]);
    }

    result[itemKey as keyof IEmotionTagsForYear] = hotelAvg;
  }

  return result;
};
