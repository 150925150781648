import { FC } from "react";
import { IMapsFiltersProps } from "./types";
import { FilterDropdown } from "../FilterDropdown";
import { transformCamelCase } from "../../utils/transformString";
import { addFilter } from "../../utils/addFilter";
import { IMapsFilter } from "../../models/IMapsFilter";
import { Button } from "react-bootstrap";
import { FilterItemList } from "../FilterItemList";
import "./styles.scss";

export const MapsFilters: FC<IMapsFiltersProps> = ({
  currentFilters,
  setCurrentFilters,
  availableFilters,
  setLastFilter,
}) => {
  const resetFilters = () => {
    setCurrentFilters({
      Category: [],
      "Brand Tier": [],
    });
  };

  return (
    <fieldset className="mt-4 border border-secondary p-4 rounded">
      <legend className="w-auto float-none px-2">Filters</legend>

      <div className="d-flex gap-2 flex-wrap flex-row w-100">
        {Object.keys(availableFilters).map((filter) => {
          return (
            <div key={filter} className="filter-wrapper">
              <FilterDropdown
                title={transformCamelCase(filter)}
                setValue={(value: string) => {
                  setLastFilter(filter);
                  setCurrentFilters(addFilter(currentFilters, filter, value));
                }}
                selectedValues={currentFilters[filter as keyof IMapsFilter]}
                availableValues={availableFilters[filter as keyof IMapsFilter]}
              />
              {!!currentFilters[filter as keyof IMapsFilter].length && (
                <div className="rounded border border-secondary mt-3 p-2 d-flex flex-column align-items-start">
                  {currentFilters[filter as keyof IMapsFilter].map(
                    (filterValue: string) => {
                      return (
                        <FilterItemList
                          key={filterValue}
                          value={filterValue}
                          setValue={(value: string) => {
                            setCurrentFilters(
                              addFilter(currentFilters, filter, value)
                            );
                          }}
                        />
                      );
                    }
                  )}
                </div>
              )}
            </div>
          );
        })}
        <div>
          <Button onClick={resetFilters} variant="secondary">
            Reset filters
          </Button>
        </div>
      </div>
    </fieldset>
  );
};
