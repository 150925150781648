import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { IEmotionTagsProps } from "./types";
import { IEmotionTags, ITagItem } from "../../models/IEmotionTags";
import { transformKebabCase } from "../../utils/transformString";
import { chartColors, monthFull } from "../../consts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IFormattedTags {
  "Bed & Breakfast & Inns,Lodging,Hotels": number[];
  "Hotels,Lodging,Resorts": number[];
  Hotel: number[];
  Resort: number[];
  "Hotels & Motels": number[];
}

export const EmotionTags: React.FC<IEmotionTagsProps> = ({
  dataset,
  month,
}) => {
  const getFormattedDataset = () => {
    const res = [];
    const groupedCategories = {} as IFormattedTags;
    for (let tag in dataset) {
      for (let tagItem in dataset[tag as keyof IEmotionTags]) {
        if (tagItem in groupedCategories) {
          groupedCategories[tagItem as keyof IFormattedTags].push(
            dataset[tag as keyof IEmotionTags][tagItem as keyof IFormattedTags]
          );
        } else {
          groupedCategories[tagItem as keyof IFormattedTags] = [
            dataset[tag as keyof IEmotionTags][tagItem as keyof IFormattedTags],
          ];
        }
      }
    }

    for (let tag in groupedCategories) {
      res.push({
        label: transformKebabCase(tag),
        data: groupedCategories[tag as keyof IFormattedTags],
        backgroundColor: chartColors[res.length],
        borderColor: chartColors[res.length],
      });
    }

    return res;
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Emotion Tags by Hotel Type for " + monthFull[month - 1],
      },
    },
  };

  const data = {
    labels: Object.keys(dataset),
    datasets: getFormattedDataset(),
  };

  return <Bar options={options} data={data} />;
};
