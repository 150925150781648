import { IEmotionTags, ITagItem } from "../models/IEmotionTags";
import { IHotel } from "../models/IHotel";

export const getEmotionTagsForMonthByTypes = (data: IHotel[]) => {
  let lastYear = 0;
  let lastMonth = 4;

  for (let hotel of data) {
    if (+hotel.year > lastYear) {
      lastYear = +hotel.year;
    }
  }

  const currentMonthData = data.filter(
    (hotel) => +hotel.month === lastMonth && +hotel.year === lastYear
  );

  const getScheme = (): IEmotionTags => {
    const getValuesObj = (): ITagItem => {
      return {
        "Bed & Breakfast & Inns,Lodging,Hotels": 0,
        "Hotels,Lodging,Resorts": 0,
        Hotel: 0,
        Resort: 0,
        "Hotels & Motels": 0,
      };
    };
    return {
      anger: getValuesObj(),
      fear: getValuesObj(),
      joy: getValuesObj(),
      love: getValuesObj(),
      sadness: getValuesObj(),
      surprise: getValuesObj(),
    };
  };
  const itemsSum: IEmotionTags = getScheme();
  const itemsLength: IEmotionTags = getScheme();

  for (let hotel of currentMonthData) {
    const hotelType = hotel["Hotel Type"];
    itemsSum[hotel.emotion_tag as keyof IEmotionTags][
      hotelType as keyof ITagItem
    ] += +hotel.emotion_score;

    itemsLength[hotel.emotion_tag as keyof IEmotionTags][
      hotelType as keyof ITagItem
    ] += 1;
  }
  const result: IEmotionTags = getScheme();

  for (let tagKey in itemsSum) {
    const tagAvg = {} as ITagItem;
    const tagSum = itemsSum[tagKey as keyof IEmotionTags];
    const tagLength = itemsLength[tagKey as keyof IEmotionTags];

    for (let tagItemKey in tagSum) {
      const avgCalcRes =
        tagSum[tagItemKey as keyof ITagItem] /
        tagLength[tagItemKey as keyof ITagItem];
      tagAvg[tagItemKey as keyof ITagItem] = isNaN(avgCalcRes) ? 0 : avgCalcRes;
    }

    result[tagKey as keyof IEmotionTags] = tagAvg;
  }
  return result;
};
