import { IFileInputStateViewProps } from "./types";
import "./style.scss";
import { Alert, Form } from "react-bootstrap";
import { ChangeEvent, useState } from "react";
import csv from "csvtojson";
import xlsxParser from "xlsx-parse-json";
import { IData } from "../../models/IData";
import { isFileValid } from "../../utils/isFileValid";

export const FileInputStateView: React.FC<IFileInputStateViewProps> = ({
  setData,
  data,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const example = {
    State: "",
    "Avg Sentiment Score this month": "",
    "Percentage Change over last month": "",
    "Avg Emotion Score this month": "",
    "Number of Hotels": "",
  };
  const onLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      if (file.type === "text/csv") {
        const fileContent = await file.text();
        const csvData = await csv().fromString(fileContent);
        if (isFileValid(csvData, example)) {
          setData(csvData);
          setIsError(false);
        } else {
          setIsError(true);
        }
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        xlsxParser.onFileSelection(file).then((data: IData) => {
          if (isFileValid(data.StateView, example)) {
            setData(data.StateView);
            setIsError(false);
          } else {
            setIsError(true);
          }
        });
      }
    }
  };

  return null;

  return (
    <>
      <fieldset className="mt-3 border border-secondary p-4 rounded file-input__wrapper">
        <legend className="w-auto float-none px-2">
          Upload your StateView file here
        </legend>
        <Form.Group>
          <Form.Control
            type="file"
            className="file-input__wrapper__input"
            onChange={onLoadFile}
          />
        </Form.Group>
      </fieldset>
      {isError && (
        <Alert
          variant="danger"
          onClose={() => setIsError(false)}
          dismissible
          className="mt-2"
        >
          <Alert.Heading>Invalid file!</Alert.Heading>
          <p>
            Please check fields in your file and try again.{" "}
            {data && "(previous file's content is shown)"}.
          </p>
        </Alert>
      )}
    </>
  );
};
