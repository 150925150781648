import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { chartColors, month } from "../../consts";
import { IMonthSentimentProps } from "./types";
import { IMonthSentiment } from "../../models/IMonthSentiment";
import { transformKebabCase } from "../../utils/transformString";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const MonthSentiment: React.FC<IMonthSentimentProps> = ({ dataset }) => {
  const getFormattedDataset = () => {
    const res = [];
    for (let key in dataset) {
      res.push({
        label: transformKebabCase(key),
        data: dataset[key as keyof IMonthSentiment],
        backgroundColor: chartColors[res.length],
        borderColor: chartColors[res.length],
      });
    }
    return res;
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Aspect Based Sentiment Across Month",
      },
    },
  };

  const data = {
    labels: month,
    datasets: getFormattedDataset(),
  };

  return <Line options={options} data={data} />;
};
